import React from 'react';

const TeltonikaSetupGuide = ({ rut }: any) => {
  return (
    <div className="font-light p-6  rounded-lg ">
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">Teltonika RUTX Reitittimen käyttöönotto</h1>
      <p className="text-gray-700 mb-6">Tämä opas auttaa sinua ottamaan käyttöön Teltonika RUT-sarjan reitittimen ja lataamaan siihen valmiiksi asetetun konfiguraatiotiedoston.</p>

      <h2 className="text-xl font-bold text-gray-800 mb-3">Vaihe 1: Reitittimen kytkeminen</h2>
      <p className="text-gray-700 mb-4">Liitä reititin virtalähteeseen ja odota, kunnes käynnistys on valmis. Tämä näkyy yleensä LED-valojen vakaana palamisena.</p>

      <h2 className="text-xl font-bold text-gray-800 mb-3">Vaihe 2: Laiteyhteyden muodostaminen</h2>
      <p className="text-gray-700 mb-4">Yhdistä tietokoneesi reitittimeen verkkokaapelilla LAN portin kautta. Varmista, että tietokoneesi on asetettu automaattisesti hankkimaan IP-osoite (DHCP).</p>

      <h2 className="text-xl font-bold text-gray-800 mb-3">Vaihe 3: Kirjautuminen reitittimen hallintapaneeliin</h2>
      <p className="text-gray-700 mb-4">
        Avaa verkkoselain ja kirjoita osoiteriville reitittimen oletus IP-osoite, tyypillisesti <strong className="font-semibold">192.168.1.1</strong>. Kirjaudu sisään käyttämällä käyttäjätunnusta ja
        salasanaa. Oletus käyttäjätunnus on <strong className="font-semibold">admin</strong> ja salasanan löytää reitittimen pohjassa olevasta tarrasta.
      </p>

      <h2 className="text-xl font-bold text-gray-800 mb-3">Vaihe 4: Konfiguraatiotiedoston lataaminen</h2>
      <p className="text-gray-700 mb-4">
        Varmista ensin, että olet ladannut oikean mallin konfiguraatiotiedoston hallintapaneelista. Tuetut mallit tällä hetkellä ovat Teltonika RUTX08, RUTX09,RUTM08 ja RUTM09.
      </p>
      <p className="text-gray-700 mb-4">
        Siirry hallintapaneelin <strong className="font-semibold">'System' {'->'} 'Backup'</strong> osioon. Valitse <strong className="font-semibold">'Upload file'</strong> ja etsi tietokoneeltasi
        varmuuskopioitu konfiguraatiotiedosto. Valitse tiedosto ja paina <strong className="font-semibold">'Upload'</strong>. Varmuuskopion palauttaminen saattaa vaihdella reitittimen mallin mukaan.
      </p>

      <h2 className="text-xl font-bold text-gray-800 mb-3">Vaihe 5: Reitittimen uudelleenkäynnistys</h2>
      <p className="text-gray-700">
        Kun konfiguraatiotiedosto on ladattu, reititin uudelleenkäynnistyy automaattisesti. Kun reititin on käynnistynyt uudelleen niin laite on käyttövalmis ja voit jatkaa kameroiden kytkemisellä.
      </p>
    </div>
  );
};

export default TeltonikaSetupGuide;
