import React from 'react';
import { useAuth } from '../../context/authContext';
import { v4 as uuidv4 } from 'uuid'; // Importtaa uuid-kirjaston v4-funktio
import { useToast } from '../../context/toastContext';
import { TextField, Button } from '@mui/material';

const CreateInvintation = ({ setOpenModal }: any) => {
  const { user, currentUser } = useAuth();
  const { showToast } = useToast();
  const [invintatiton, setInvintation] = React.useState({
    customerCode: null as string | null,
    email: null as string | null,
    /*     companyName: '' as string,
    vatNumber: null as string | null, */

    billingCustomerId: user?.billingCustomerId,
    token: uuidv4(),
    role: 'BILLINGCUSTOMER',
  });

  async function createInvintation() {
    if (invintatiton.email && invintatiton.customerCode && currentUser) {
      const token = await currentUser.getIdToken();

      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/create-invitation-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(invintatiton),
      });
      const data = await response.json();
      if (response.status === 200) {
        setOpenModal(false);
        showToast(`Kutsu lähetetty sähköpostiin ${invintatiton.email}!`);
      } else {
        showToast(data.message);
      }
    } else {
      showToast('Please fill in all fields');
    }
  }

  return (
    <div className="w-full justify-center flex flex-col items-center min-w-[600px] ">
      <div className="p-5 w-4/5 flex flex-col justify-center gap-5 ">
        {/* <TextField type="text" label="Yritys" onChange={(e) => setInvintation({ ...invintatiton, companyName: e.target.value })} />
        <TextField type="text" label="Y-Tunnus" onChange={(e) => setInvintation({ ...invintatiton, vatNumber: e.target.value })} /> */}
        <TextField required type="text" label="Sähköposti" onChange={(e) => setInvintation({ ...invintatiton, email: e.target.value })} />
        <TextField required type="text" label="Viite / Asiakasnumero" onChange={(e) => setInvintation({ ...invintatiton, customerCode: e.target.value })} />
      </div>
      <div className="p-5 w-full flex flex-col justify-center items-center hover:scale-105 ease-in-out duration-300 ">
        <Button
          sx={{
            borderRadius: 6,
            width: '50%',
            padding: '10px',
            justifyContent: 'center',
          }}
          variant="contained"
          onClick={createInvintation}
        >
          Lähetä kutsu
        </Button>
      </div>
    </div>
  );
};

export default CreateInvintation;
