import React, { useState, useEffect } from 'react';
import { Input } from '@material-tailwind/react';
import { Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

const RemoteUser = ({ customer, setRemoteUsers }: any) => {
  const [localremoteusers, setlocalremoteusers] = useState(customer.remoteUsers);
  useEffect(() => {
    setRemoteUsers(localremoteusers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localremoteusers]);

  const handleAddRemoteUser = () => {
    setlocalremoteusers([...localremoteusers, { name: '', email: '', phone: '' }]);
  };
  const handleRemoveRemoteUser = (index: number) => {
    if (localremoteusers.length === 1) {
      setlocalremoteusers([{ name: '', email: '', phone: '' }]);
      return;
    }
    setlocalremoteusers(localremoteusers.filter((_: any, i: any) => i !== index));
  };
  const handleNameChange = (index: number, value: any) => {
    const newlocalremoteusers = [...localremoteusers];
    newlocalremoteusers[index].name = value;
    setlocalremoteusers(newlocalremoteusers);
  };
  const handleEmailChange = (index: number, value: any) => {
    const newlocalremoteusers = [...localremoteusers];
    newlocalremoteusers[index].email = value;
    setlocalremoteusers(newlocalremoteusers);
  };
  const handlePhoneChange = (index: number, value: any) => {
    const newlocalremoteusers = [...localremoteusers];
    newlocalremoteusers[index].phone = value;
    setlocalremoteusers(newlocalremoteusers);
  };
  const sx = {
    fontFamily: 'open-sans',
    fontSize: '1rem',
    fontWeight: 600,
    color: '#5c5c5c',
  };
  const isEmptyArray = localremoteusers.length === 1 && localremoteusers[0].name === '';

  return (
    <div className="bg-greyBackground rounded-md p-6 mb-4 flex-col">
      <Typography
        className="text-almostBlack "
        sx={{
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#5c5c5c',
          textAlign: 'center',
          fontFamily: 'Poppins',
        }}
      >
        Lisää käyttäjät
      </Typography>

      {localremoteusers.map((user: any, index: number) => {
        const isDeletable = (localremoteusers.length && localremoteusers[index].name !== '') || localremoteusers.length > 1;

        return (
          <div key={index} className="flex flex-col w-full mb-4 border p-4 pr-8 rounded-lg relative">
            <div className="mb-4 font-poppins flex flex-row">
              <Input
                type="text"
                crossOrigin="true"
                variant="standard"
                value={user.name}
                style={sx}
                onChange={(e) => handleNameChange(index, e.target.value)}
                label={index + 1 + '. Käyttäjän nimi'}
                color="light-blue"
              />
              {isDeletable && (
                <div
                  onClick={() => handleRemoveRemoteUser(index)}
                  className="cursor-pointer  flex flex-row items-center gap-2 justify-center transition-transform transform hover:scale-110 ease-in-out duration-300"
                >
                  <DeleteForeverIcon style={{ color: '#ff4f4b', scale: '1.5' }} />
                </div>
              )}
            </div>
            <div className="flex flex-wrap justify-between">
              <div className="mb-4 font-poppins min-w-[45%]">
                <Input
                  type="email"
                  variant="standard"
                  crossOrigin="true"
                  style={sx}
                  value={user.email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  label="Sähköposti"
                  color="light-blue"
                />
              </div>
              <div className="mb-4 font-poppins min-w-[45%]">
                <Input
                  type="text"
                  variant="standard"
                  crossOrigin="true"
                  style={sx}
                  value={user.phone}
                  onChange={(e) => handlePhoneChange(index, e.target.value)}
                  label="Puhelinnumero"
                  color="light-blue"
                />
              </div>
            </div>
            <Divider
              sx={{
                backgroundColor: '#5c5c5c',
                height: '1px',
                width: '100%',
                margin: '0 auto',
                boxShadow: '0px 2px 1px 1px #2c5c5c',
                opacity: '0.3',
                marginTop: '1rem',
              }}
            />
          </div>
        );
      })}
      <div className="flex mt-auto justify-end">
        <Button
          onClick={handleAddRemoteUser}
          disabled={isEmptyArray}
          className="addCamIcon"
          variant="text"
          color="inherit"
          style={{
            fontSize: '1rem',
            fontWeight: 600,
            color: isEmptyArray ? '#D8D8D8' : '#5c5c5c',
            fontFamily: 'open-sans',
          }}
          startIcon={<AccountCircleIcon style={{ color: isEmptyArray ? '#D8D8D8' : '#5c5c5c' }} />}
        >
          Lisää uusi käyttäjä
        </Button>
      </div>
    </div>
  );
};

export default RemoteUser;
