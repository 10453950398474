import React, { useState, useEffect } from 'react';
import { Video } from 'lucide-react';
import { Input } from '@material-tailwind/react';
import { Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
type Camera = {
  name: string;
  location: string;
};
const CameraAmount = ({ customer, updateCustomer, setCameras, submitCustomer, isReseller }: any) => {
  const [localCameras, setLocalCameras] = useState(customer.cameras);
  const [cameraLimitExceeded, setCameraLimitExceeded] = useState(false);

  useEffect(() => {
    setCameras(localCameras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCameras]);
  // Use useEffect to handle the camera limit logic
  useEffect(() => {
    let limit = isReseller ? 2 : 13;
    const cameras = localCameras.filter((camera: Camera) => camera.name !== '');

    if (cameras.length >= limit || localCameras.length === limit) {
      setCameraLimitExceeded(true);
    } else {
      setCameraLimitExceeded(false);
    }
  }, [localCameras, isReseller]);
  const handleAddCamera = () => {
    const newCamera = { name: '', location: '' };
    setLocalCameras([...localCameras, newCamera]);
  };
  const handleRemoveCamera = (index: any) => {
    if (localCameras.length === 1) {
      setLocalCameras([{ name: '', location: '' }]);
      return;
    }
    setLocalCameras(localCameras.filter((_: any, i: any) => i !== index));
  };

  const handleNameChange = (index: any, value: any) => {
    const newCameras = [...localCameras];
    newCameras[index].name = value;
    setLocalCameras(newCameras);
  };

  const handleLocationChange = (index: any, value: any) => {
    const newCameras = [...localCameras];
    newCameras[index].location = value;
    setLocalCameras(newCameras);
  };
  const isEmptyArray = (localCameras.length === 1 && localCameras[0].name === '') || localCameras.length === 0;

  const sx = { fontFamily: 'open-sans', fontSize: '1rem', fontWeight: 600, color: '#5c5c5c' };

  /*   const CameraLimit = ({ localCameras }: { localCameras: Camera[] }) => {
    let limit = 13;
    if (isReseller) {
      limit = 2;
    } else {
      limit = 13;
    }
    const cameras = localCameras.filter((camera) => camera.name !== '');

    if (cameras.length >= limit || localCameras.length === limit) {
      setCameraLimitExceeded(true);
    } else {
      setCameraLimitExceeded(false);
    }
    return (
      <div className="flex absolute top-10 right-10">
        <Typography sx={{ fontSize: '1rem', fontWeight: 600, color: '#5c5c5c', textAlign: 'center', fontFamily: 'open-sans' }}>
          Kamerat ({cameras.length}/{limit})
        </Typography>
      </div>
    );
  }; */
  const disabled = cameraLimitExceeded || isEmptyArray;
  return (
    <div className="bg-greyBackground  sm:p-2 md:p-10 rounded-md shadow-md gap-6 flex flex-col mb-4 w-full relative">
      {/*  <CameraLimit localCameras={localCameras} /> */}
      <div className="flex absolute top-10 right-10">
        <Typography sx={{ fontSize: '1rem', fontWeight: 600, color: '#5c5c5c', textAlign: 'center', fontFamily: 'open-sans' }}>
          Kamerat ({localCameras.filter((camera: Camera) => camera.name !== '').length}/{isReseller ? 2 : 13})
        </Typography>
      </div>
      <div>
        <Typography sx={{ fontSize: '1.25rem', fontWeight: 600, color: '#5c5c5c', textAlign: 'center', letterSpacing: 0.8, paddingBottom: 6, fontFamily: 'Poppins' }}>
          Kamerat ja niiden sijainnit
        </Typography>

        {localCameras.map((camera: Camera, index: number) => (
          <div key={index} className="flex items-center gap-4 py-4  ">
            <Typography sx={{ fontSize: '1.15rem', fontWeight: 600, color: '#5c5c5c', fontFamily: 'open-sans' }}>{index + 1}.</Typography>
            <Input
              required
              type="text"
              value={camera.name}
              crossOrigin="true"
              onChange={(e) => handleNameChange(index, e.target.value)}
              label="Kameran nimi"
              color="light-blue"
              style={sx}
              variant="standard"
            />
            <Input
              type="text"
              crossOrigin="true"
              value={camera.location}
              onChange={(e) => handleLocationChange(index, e.target.value)}
              label="Sijainti (esim. SK201)"
              color="light-blue"
              style={sx}
              variant="standard"
            />
            {localCameras.length > 0 && localCameras[0].name !== '' && (
              <div
                onClick={() => handleRemoveCamera(index)}
                className="cursor-pointer  flex flex-row items-center gap-2 justify-center transition-transform transform hover:scale-110 ease-in-out duration-300"
              >
                <DeleteForeverIcon style={{ color: '#ff4f4b', scale: '1.5' }} />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={`flex justify-center ease-in-out duration-700 ${disabled ? '' : 'hover:scale-110'}`}>
        <Button
          onClick={handleAddCamera}
          disabled={disabled}
          className="addCamIcon"
          variant="text"
          color="inherit"
          style={{ fontSize: '1rem', fontWeight: 600, color: disabled ? '#D8D8D8' : '#5c5c5c', fontFamily: 'open-sans' }}
          startIcon={<Video style={{ color: disabled ? '#D8D8D8' : '#5c5c5c' }} size={28} />}
        >
          {disabled ? 'Kameraraja täynnä' : 'Lisää uusi kamera'}
        </Button>
      </div>
    </div>
  );
};

export default CameraAmount;
