import { PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, multiFactor } from 'firebase/auth';
import { auth } from '../firebase'; // Oletus, että Firebase Auth on alustettu
import { Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useAuth } from './authContext';
import { useToast } from './toastContext';
const Enable2FA = () => {
  const { currentUser } = useAuth();
  const { showToast } = useToast();
  const [twoFAEnabled, setTwoFAEnabled] = useState(false);
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

  // Tarkista, onko 2FA käytössä
  useEffect(() => {
    if (currentUser) {
      const multiFactorUser = multiFactor(currentUser);
      if (multiFactorUser.enrolledFactors.length > 0) {
        setTwoFAEnabled(true);
      } else {
        setTwoFAEnabled(false);
      }
    }
  }, [currentUser]);

  const createRecaptchaVerifier = () => {
    return new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response: any) => {},
      'expired-callback': () => {
        recaptchaVerifierRef.current = null; // Nollataan, jotta se voidaan alustaa tarvittaessa uudelleen.
      },
    });
  };

  const addPhoneNumberFor2FA = async () => {
    if (!currentUser) {
      showToast('Et ole kirjautuneena', { type: 'error' });
      return;
    }
    auth.languageCode = 'fi'; // Aseta kieli (valinnainen)

    // Pyydä käyttäjältä puhelinnumero
    const phoneNumber = window.prompt('Syötä puhelinnumerosi kansainvälisessä muodossa (esim. +358401234567):');
    if (!phoneNumber) return;

    if (!phoneNumber.startsWith('+')) {
      showToast('Virheellinen puhelinnumero. Käytä kansainvälistä muotoa.', { type: 'error' });
      return;
    }
    recaptchaVerifierRef.current = createRecaptchaVerifier();
    const multiFactorSession = await multiFactor(currentUser)
      .getSession()
      .then((session) => {
        return session;
      });

    const phoneInfoOptions = {
      phoneNumber: phoneNumber,
      session: multiFactorSession,
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);
    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifierRef.current).then(function (verificationId) {
      // verificationId will be needed to complete enrollment.
      const verificationCode = window.prompt('Syötä tekstiviestillä saama vahvistuskoodi:');
      if (!verificationCode) {
        recaptchaVerifierRef.current?.clear();
        return;
      }
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      multiFactor(currentUser).enroll(multiFactorAssertion, 'My personal phone number');
      showToast('Puhelinnumero lisätty 2FA:ta varten onnistuneesti.', { type: 'success' });
      recaptchaVerifierRef.current?.clear();
    });
  };

  return (
    <div className="flex flex-row justify-between gap-2 items-center w-full">
      <Typography
        sx={{
          fontFamily: 'poppins',
          fontSize: '1rem',
          fontWeight: 600,
          color: 'almost-black',
        }}
      >
        Kaksivaiheinen tunnistautuminen (SMS)
      </Typography>
      <div id="recaptcha-container"></div>
      <button
        disabled={twoFAEnabled}
        onClick={() => addPhoneNumberFor2FA()}
        className="bg-blueBackground disabled:bg-blue-gray-300 text-sm p-2 rounded-3xl hover:bg-primary hover:scale-110 ease-in-out duration-300"
      >
        {twoFAEnabled ? '2FA käytössä' : 'Ota 2FA käyttöön'}
      </button>
    </div>
  );
};

export default Enable2FA;
