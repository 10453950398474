import React, { createContext, useEffect, useState, useContext, ReactNode } from 'react';
import { GridLoader } from 'react-spinners';
interface LoadingContextProps {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
}

const LoadingContext = createContext<LoadingContextProps | undefined>(undefined);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const LoadingScreen = ({ loading }: { loading: boolean }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [fadeClass, setFadeClass] = useState('');

    useEffect(() => {
      if (loading) {
        setIsVisible(true);
        setFadeClass('opacity-100'); // Start fade-in
      } else {
        setFadeClass('opacity-0'); // Start fade-out
        // Delay unmounting to let fade-out animation complete
        const timeout = setTimeout(() => {
          setIsVisible(false);
        }, 2000); // Match this to your CSS transition duration

        return () => clearTimeout(timeout);
      }
    }, [loading]);

    if (!isVisible) return null;

    return (
      <div className={`w-screen h-screen absolute top-0 left-0 bg-opacity-45 bg-blue-gray-500 flex justify-center items-center transition-opacity duration-2000 ${fadeClass}`}>
        <div className="flex flex-col items-center">
          <div className="loader flex gap-8 flex-col"></div>
          <GridLoader color={'white'} loading={loading} size={45} />
          <p className="text-white font-poppins text-2xl font-bold p-8">Ladataan...</p>
        </div>
      </div>
    );
  };

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading }}>
      <LoadingScreen loading={isLoading} />
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = (): LoadingContextProps => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
