import React, { useState } from 'react';
import CustomizedSteppers from './stepper';
import BasicInfo from './steps/basicInfo';
import ContactPerson from './steps/contactPerson';
import CameraAmount from './steps/cameraAmount';
import RemoteUser from './steps/remoteUser';
import CheckCustomerData from './steps/checkCustomer';
import { useEffect } from 'react';
import { sendCustomer } from './submitNewCustomer';
import { useCustomer, CustomerContextType } from '../../context/customerContext';
import LoadingGear from '../../components/loadingGear';
import { useAuth } from '../../context/authContext';
import { useToast } from '../../context/toastContext';
//import { Button } from '@material-tailwind/react';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { handleNextValidation } from './functions/handleNextValidation';
import { useApi } from '../../context/ApiCalls';
import { useLoading } from '../../context/loadingContext';
interface BasicInfoType {
  name: string;
  street: string;
  city: string;
  postalCode: string;
}

interface ContactPersonType {
  name: string;
  email: string;
  phone: string;
}

interface Camera {
  name: string;
  location: string;
}

interface RemoteUserType {
  name: string;
  email: string;
  phone: string;
}

interface Customer {
  createdAt: Date;
  owner: string;
  basicInfo: BasicInfoType;
  contactPerson: ContactPersonType;
  cameras: Camera[];
  remoteUsers: RemoteUserType[];
  role: string;
}
interface Errors {
  name?: string;
  street?: string;
  cameras?: string;
  remoteUsers?: string;
}

const AddNewCustomer = () => {
  const { currentUser, user } = useAuth();
  const { getSites } = useApi();
  const { showToast } = useToast();
  //eslint-disable-next-line
  const [errors, setErrors] = useState<Errors>({});
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState<Errors>({});
  const [customer, setCustomer] = useState({
    createdAt: new Date(),
    owner: currentUser.uid,
    basicInfo: { name: '', street: '', city: '', postalCode: '' },
    contactPerson: { name: '', email: '', phone: '' },
    cameras: [{ name: '', location: '' }],
    remoteUsers: [{ name: '', email: '', phone: '' }],
    role: user?.role,
  });
  const [isVisible, setIsVisible] = useState(true);
  const { loadingActive, setCustomerID, state, setState, siteDone, resetState } = useCustomer() as CustomerContextType;
  const [activeStep, setActiveStep] = useState(0);
  const [demoIsUsed, setDemoIsUsed] = useState<boolean | null>(null);
  const isReseller = user?.role === 'RESELLER';
  useEffect(() => {
    if (siteDone) {
      resetState();
      navigate('/dashboard');
    } // eslint-disable-next-line
  }, [siteDone]);

  useEffect(() => {
    setLoading(true);
    if (isReseller) {
      (async () => {
        const sites = await getSites();
        if (sites && sites.length > 0) {
          setLoading(false);
          setDemoIsUsed(true);
        } else {
          setLoading(false);
          setDemoIsUsed(false);
        }
      })();
    } else {
      setLoading(false);
      setDemoIsUsed(false);
    }
    // eslint-disable-next-line
  }, []);

  if (demoIsUsed === null) {
    return <div></div>;
  }
  if (demoIsUsed) {
    return (
      <div className=" flex justify-center items-center h-full p-6  text-2xl font-bold text-white ">
        <div className=" flex justify-evenly flex-col items-center h-1/3 p-6  text-xl font-semibold text-almostBlack bg-white rounded-lg max-w-[600px]">
          <p className="font-poppins text-center h-1/2 justify-center items-center">
            Sinulla on jo maksimimäärä demo asiakkaita, mikäli tarvitset useamman demokohteen niin ota yhteyttä asiakaspalveluun!
          </p>
          <Link to="/dashboard" className="p-4">
            <Button variant="contained" color="primary">
              Siirry hallintapaneeliin
            </Button>
          </Link>
        </div>
      </div>
    );
  }
  const updateCustomer = (section: keyof Customer, key: keyof BasicInfoType | keyof ContactPersonType | keyof Camera | keyof RemoteUserType, value: string | number) => {
    setCustomer((prev) => {
      const newCustomer = { ...prev };
      newCustomer[section][key] = value;
      return newCustomer;
    });
  };

  const handleNext = () => {
    const valid = handleNextValidation({
      activeStep,
      customer,
      setErrorMessages,
      showToast,
    });

    if (!valid) return;
    setIsVisible(false);
    setTimeout(() => {
      setActiveStep((prev) => prev + 1);
      setIsVisible(true);
    }, 500); // 500ms on sama kuin animaation kesto
  };

  const handleBack = () => {
    setIsVisible(false);
    setTimeout(() => {
      setActiveStep((prev) => prev - 1);
      setIsVisible(true);
    }, 500); // 500ms on sama kuin animaation kesto
  };

  const handleSubmit = async () => {
    // Jos validointi onnistui, jatka käsittelyä
    const response = await sendCustomer({ customer, currentUser, role: user?.role });
    setCustomerID(response);
  };

  const submitCustomer = () => {
    setActiveStep((prev) => prev + 1);
  };

  const setCameras = (cameras: Camera[]) => {
    setCustomer((prev) => {
      const newCustomer = { ...prev };
      newCustomer.cameras = cameras;
      return newCustomer;
    });
  };
  const setRemoteUsers = (remoteUsers: RemoteUserType[]) => {
    setCustomer((prev) => {
      const newCustomer = { ...prev };
      newCustomer.remoteUsers = remoteUsers;
      return newCustomer;
    });
  };

  const steps = [
    <BasicInfo customer={customer} updateCustomer={updateCustomer} submitCustomer={submitCustomer} errorMessages={errorMessages} />,
    <ContactPerson customer={customer} updateCustomer={updateCustomer} submitCustomer={submitCustomer} errorMessages={errorMessages} />,
    <CameraAmount customer={customer} updateCustomer={updateCustomer} setCameras={setCameras} submitCustomer={submitCustomer} isReseller={isReseller} />,
    <RemoteUser customer={customer} updateCustomer={updateCustomer} submitCustomer={submitCustomer} setRemoteUsers={setRemoteUsers} />,
    <CheckCustomerData customer={customer} errors={errors} />,
  ];

  if (loadingActive) {
    return <LoadingGear state={state} setState={setState} />;
  }
  const styles = {
    padding: '10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'poppins',
    borderRadius: 30,
    fontWeight: 600,
    margin: '20px',
    color: 'white',
    backgroundColor: '#43687e',
    '&:hover': {
      backgroundColor: '#2c4f5a',
    },
  };
  return (
    <div className="flex justify-center items-center h-full p-6 ">
      <div className="max-w-[1000px] w-full h-full">
        <CustomizedSteppers activeStep={activeStep} />
        <div className={`step-content ${isVisible ? 'fade-in' : 'fade-out'}`}>{steps[activeStep]}</div>
        <div className="stepperNavi p-8">
          <div className="hover:scale-110 ease-in-out duration-300">
            <Button
              variant="contained"
              sx={{
                ...styles,
                backgroundColor: '#7e888e',
                '&:hover': {
                  backgroundColor: '#9da4a9',
                },
              }}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              TAKAISIN
            </Button>
          </div>
          <div className="hover:scale-110 ease-in-out duration-300 ">
            {activeStep === steps.length - 1 ? (
              <Button
                sx={{
                  padding: '10px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontFamily: 'poppins',
                  borderRadius: 30,
                  fontWeight: 600,
                  color: 'white',
                  backgroundColor: '#43687e',
                  '&:hover': {
                    backgroundColor: '#2c4f5a',
                  },
                }}
                color="success"
                variant="contained"
                onClick={handleSubmit}
              >
                NÄYTTÄÄ HYVÄLTÄ - LISÄÄ ASIAKAS
              </Button>
            ) : (
              <Button variant="contained" sx={styles} onClick={handleNext}>
                SEURAAVA
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCustomer;
