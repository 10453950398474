import React, { createContext, ReactNode, useContext } from 'react';
import { useAuth, type UserType } from './authContext';
import { useToast } from './toastContext';
import { useLoading } from './loadingContext';
// Define types for Camera, RemoteUser, Server, etc.
/* interface Camera {
  cameraName: string;
  cameraLocation: string;
}
 */
interface RemoteUser {
  name: string;
  email: string;
  phone: string;
}

interface ApiContextType {
  deleteCustomerAndData: (args: { id: number }) => Promise<Response | undefined>;
  deleteCamera: (args: { camera: string; sub: number }) => Promise<Response | undefined>;
  deleteRemoteUser: (remoteUser: number) => Promise<Response | undefined>;
  addCamera: (args: { camera: any; siteID: number }) => Promise<Response | unknown>;
  addRemoteUser: (args: { remoteUser: RemoteUser; siteID: number }) => Promise<Response | boolean>;
  updateCamera: (camera: any) => Promise<Response | undefined>;
  resellerClients: (args: { user: UserType }) => Promise<any>; // Adjust as needed
  billingCustomer: () => Promise<any>; // Adjust as needed
  getSites: () => Promise<any>; // Adjust as needed
  downloadFile: (url: string) => Promise<void>;
  getServers: () => Promise<any>; // Adjust as needed
  newServer: (server: any) => Promise<Response | undefined>; // Define server type if known
  updateServerLicense: (args: { serverId: number; newLicenseCount: number }) => Promise<Response | undefined>;
}
export const ApiContext = createContext<ApiContextType | null>(null);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useAuth();
  const { showToast } = useToast();
  const { setLoading } = useLoading();

  const baseURL = process.env.REACT_APP_BACKEND_URL;
  /**
   * Deletes a customer and all associated data from the database and external systems.
   *
   * @param {number} id  The customer ID
   
   * @returns {Promise<Response | undefined>}
   */
  const deleteCustomerAndData = async ({ id }: { id: number }): Promise<Response | undefined> => {
    const token = await currentUser.getIdToken();
    const uid = currentUser.uid;
    try {
      const response = await fetch(baseURL + '/delete-customer-all/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id, uid }),
      });

      return response;
    } catch (error) {
      showToast('Virhe asiakkaan ja datan poistamisessa. Yritä uudelleen.', { type: 'error' });
      return undefined;
    }
  };

  const deleteCamera = async ({ camera, sub }: { camera: string; sub: number }): Promise<any> => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/delete-camera/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          camera: camera,
          subID: sub,
          uid: currentUser.uid,
        }),
      });
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      showToast('Virhe kameran poistamisessa. Yritä uudelleen.', { type: 'error' });
      return false;
    }
  };

  const deleteRemoteUser = async (remoteUser: number) => {
    // setLoading(true);
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/delete-remote-user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          remoteUser: remoteUser,
        }),
      });
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      showToast('Virhe käyttäjän poistamisessa palvelusta. Yritä uudelleen.', { type: 'error' });
    }
  };
  const updateCamera = async (camera: any) => {
    // setLoading(true);
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/update-camera/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(camera),
      });
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      showToast('Virhe kameran päivittämisessä. Yritä uudelleen.', { type: 'error' });
    }
  };

  const addCamera = async ({ camera, siteID }: { camera: { cameraName: string; cameraLocation: string }; siteID: number }): Promise<Response | unknown> => {
    // setLoading(true);
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/add-camera/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ camera, siteID, uid: currentUser.uid }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    } catch (error) {
      setLoading(false);
      showToast('Virhe kameran lisäämisessä. Yritä uudelleen.', { type: 'error' });
    }
  };

  const addRemoteUser = async ({ remoteUser, siteID }: { remoteUser: { name: string; email: string; phone: string }; siteID: number }) => {
    // setLoading(true);
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/add-remote-user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ remoteUser, siteID }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      showToast('Virhe käyttäjän lisäämisessä palveluun. Yritä uudelleen.', { type: 'error' });
      return false;
    }
  };

  const resellerClients = async ({ user }: any) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/reseller-clients/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ user }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      showToast('Virhe asiakkaiden hakemisessa. Yritä uudelleen.', { type: 'error' });
    }
  };
  const billingCustomer = async () => {
    // setLoading(true);
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/billing-customer/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const billingCustomers = data.billingCustomers;
      setLoading(false);
      return billingCustomers;
    } catch (error) {
      setLoading(false);
      showToast('Virhe asiakastietojen hakemisessa. Yritä uudelleen.', { type: 'error' });
    }
  };

  const getServers = async () => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/admin/get-servers', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      showToast('Virhe palvelimien hakemisessa. Yritä uudelleen.', { type: 'error' });
    }
  };

  const newServer = async (server: any) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/admin/create-server', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ serverData: server }),
      });

      return response;
    } catch (error) {
      showToast('Virhe uuden palvelimen luomisessa. Yritä uudelleen.', { type: 'error' });
    }
  };

  const updateServerLicense = async ({ serverId, newLicenseCount }: { serverId: number; newLicenseCount: number }) => {
    const token = await currentUser.getIdToken();
    try {
      const response = await fetch(baseURL + '/admin/update-server-license', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ serverId, newLicenseCount }),
      });

      return response;
    } catch (error) {
      showToast('Virhe lisenssin päivittämisessä. Yritä uudelleen.', { type: 'error' });
    }
  };
  const getSites = async () => {
    const token = await currentUser.getIdToken();
    const urlWithParams = new URL(baseURL + '/user-sites');
    urlWithParams.searchParams.append('uid', currentUser.uid);

    try {
      const response = await fetch(urlWithParams.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      showToast('Virhe sivustojen hakemisessa. Yritä uudelleen.', { type: 'error' });
    }
  };

  async function downloadFile(url: string) {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/download`;
    const token = await currentUser.getIdToken();
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ url }),
    });

    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlBlob;
    link.download = 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const value: ApiContextType = {
    deleteCustomerAndData,
    deleteCamera,
    deleteRemoteUser,
    addCamera,
    addRemoteUser,
    updateCamera,
    resellerClients,
    billingCustomer,
    getSites,
    downloadFile,
    getServers,
    newServer,
    updateServerLicense,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }

  return context;
};
