import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import {
  onAuthStateChanged,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  reload,
  setPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
} from 'firebase/auth';

import { User } from 'firebase/auth';
import { auth } from '../firebase';
import { useToast } from './toastContext';
import { FirebaseError } from 'firebase/app';
import { getUserData } from './apiFunctions';
import { UserInvitation } from '../types/types';
import { handleMfa } from './handleMfa';
import { useLoading } from './loadingContext';
type AuthResponse = {
  user?: User | null; // Replace 'User' with the correct Firebase User type or your custom user type
  error?: string;
};
const dummyAuthResponse: AuthResponse = { user: undefined, error: undefined };
enum Role {
  ADMIN = 'ADMIN',
  RESELLER = 'RESELLER',
  USER = 'USER',
  BILLINGCUSTOMER = 'BILLINGCUSTOMER',
}

export type UserType = {
  id: number | null;
  address: string | null;
  billingCustomerId: number | null;
  billingID: number | null;
  companyName: string | null;
  country: string | null;
  created: string | null;
  createdAt: string | null;
  customerCode: string | null;
  email: string | null;
  name: string | null;
  phone: string | null;
  role: Role;
  uid: string | null;
  updatedAt: string | null;
  vatNumber: string | null;
  userType: string | null;
};

interface AuthContextProps {
  currentUser: any; // Tämä voi olla mukautettu tyyppi tai Firebase User -tyyppi
  verifiedUser: boolean;
  verifiedEmail: boolean;
  userBilling: boolean;
  setUserBilling: React.Dispatch<React.SetStateAction<boolean>>;
  checkEmailVerification: () => Promise<void>;
  logout: () => Promise<void>;
  registerWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  loginWithEmailAndPassword: (email: string, password: string) => Promise<AuthResponse>;
  CheckBillingInfo: () => Promise<void>;
  checkInvitationIsValid: any;
  validInvite: boolean;
  setValidInvite: React.Dispatch<React.SetStateAction<boolean>>;
  ResetPassword: (email: string) => Promise<void>;
  user: UserType | null;
  // loading: boolean;
}

export const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  verifiedUser: false,
  userBilling: false,
  setUserBilling: () => {},
  checkEmailVerification: async () => {},
  logout: async () => {},
  registerWithEmailAndPassword: async (email: string, password: string) => {},
  loginWithEmailAndPassword: async (email: string, password: string) => dummyAuthResponse,
  verifiedEmail: false,
  CheckBillingInfo: async () => {},
  checkInvitationIsValid: async (inviteCode: string) => {},
  validInvite: false,
  setValidInvite: () => {},
  ResetPassword: async (email: string) => {},
  user: null,
  // loading: false,
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any | null>(null); // Tämä voi olla mukautettu tyyppi tai Firebase User -tyyppi
  const [pending, setPending] = useState(true);
  // const [loading, setLoading] = useState(false); // Tämä voi olla mukautettu tyyppi tai Firebase User -tyyppi
  const [verifiedEmail, setVerifiedEmail] = useState(false);
  const [userBilling, setUserBilling] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState(false);
  const [validInvite, setValidInvite] = useState(false);
  const [inviteEmail, setInviteEmail] = useState<string | null>(null); // Tämä voi olla mukautettu tyyppi tai Firebase User -tyyppi
  const [user, setUser] = useState<any | null>(null);
  const [billingCustomer, setBillingCustomer] = useState<UserInvitation | null>(null);
  const { setLoading } = useLoading();
  const { showToast } = useToast();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.emailVerified) {
        setVerifiedEmail(true);
      }
      setLoading(false);
      setCurrentUser(user);
      setPending(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentUser && verifiedEmail && userBilling) {
      setVerifiedUser(true);
    }
  }, [currentUser, verifiedEmail, userBilling]);

  useEffect(() => {
    if (currentUser && verifiedEmail) {
      CheckBillingInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, verifiedEmail]);

  useEffect(() => {
    if (currentUser && verifiedEmail && !user) {
      getUserData({ currentUser: currentUser }).then((data) => {
        setLoading(false);
        setUser(data);
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, verifiedEmail, user]);

  const checkEmailVerification = async () => {
    if (currentUser && !verifiedEmail) {
      await reload(currentUser);
      if (currentUser.emailVerified) {
        setVerifiedEmail(true);
        // Voit myös ilmoittaa käyttäjälle, että sähköposti on nyt vahvistettu
        showToast('Sähköposti on nyt vahvistettu. Jatka seuraavaan vaiheeseen.', {
          type: 'success',
        });
      }
    }
  };

  const checkInvitationIsValid = async (inviteCode: string) => {
    if (billingCustomer) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-invitation-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inviteCode: inviteCode }),
      });
      const { invitation } = await response.json();

      if (invitation) {
        setInviteEmail(invitation.email);
        setValidInvite(true);
        setBillingCustomer(invitation);
      } else {
        setValidInvite(false);
      }
    } catch (error) {
      console.error('Error checking invitation:', error);
      return false;
    }
  };

  const reseller = billingCustomer?.role === 'RESELLER';

  const CheckBillingInfo = async () => {
    try {
      // Ensure currentUser is defined
      if (!currentUser) {
        console.error('currentUser is undefined');
        return;
      }

      // Get billing info from firebase users collection
      const user = await getUserData({ currentUser: currentUser });

      // Provide a default object to safely destructure 'user'
      const admin = user?.role === 'ADMIN';

      if (!user) {
        return;
      } else {
        if (user.billingID || user.billingCustomerId || reseller || admin) {
          setLoading(false);
          setUser(user);
          setUserBilling(true);
        } else {
          setLoading(false);
          setUserBilling(false);
        }
      }
    } catch (error) {
      console.error('Error checking billing info:', error);
    }
  };

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    setLoading(true);
    try {
      await setPersistence(auth, browserSessionPersistence);
      const response = await signInWithEmailAndPassword(auth, email, password);

      if (response.user) {
        const user = await getUserData({ currentUser: response.user });
        setLoading(false);
        setUser(user);

        return { user: response.user, userData: user };
      }
    } catch (error) {
      setLoading(false);
      // @ts-ignore
      if (error.code === 'auth/multi-factor-auth-required') {
        const mfa = await handleMfa({ error, setUser });
        if (mfa.user) {
          return mfa;
        } else {
          return { user: null, error: 'User not found.' };
        }
      }
      let errorMessage = 'Kirjautuminen epäonnistui. Yritä uudelleen.';
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/user-not-found':
            errorMessage = 'Käyttäjää ei löytynyt.';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Väärä salasana.';
            break;
          case 'auth/invalid-login-credentials':
            errorMessage = 'Väärä sähköpostiosoite tai salasana.';
            break;

          default:
            errorMessage = error.message;
            break;
        }
      }

      showToast(errorMessage, { type: 'error' });
    }
    return { user: null, error: 'Unexpected error occurred' };
  };

  const registerWithEmailAndPassword = async (email: string, password: string) => {
    try {
      if (validInvite && billingCustomer) {
        const match = email === inviteEmail;
        if (!match) {
          showToast('Invalid email for this invitation', {
            type: 'error',
            autoClose: 5000,
            position: 'top-center',
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
              padding: '10px',
            },
          });
          return;
        }
      }
      const userCredentials = await createUserWithEmailAndPassword(auth, email, password);

      if (userCredentials && billingCustomer) {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-billing-user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${await userCredentials.user.getIdToken()}`,
          },
          body: JSON.stringify({
            uid: userCredentials.user.uid,
            email: userCredentials.user.email,
            customerCode: billingCustomer.customerCode,
            billingCustomerId: billingCustomer.billingCustomerId,
            role: billingCustomer.role,
          }),
        });

        if (response.ok) {
          await response.json();
        } else if (!response.ok) {
          showToast('Virhe käyttäjän luomisessa. Yritä uudelleen.', { type: 'error' });
        }
      }

      await sendEmailVerification(userCredentials.user);
    } catch (error) {
      console.error('Error signing up with email and password: ', error);
      let errorMessage = 'Tuntematon virhe tapahtui. Yritä uudelleen.';

      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = 'Sähköpostiosoite on jo käytössä.';
            break;
          case 'auth/invalid-email':
            errorMessage = 'Sähköpostiosoite on virheellinen.';
            break;
          case 'auth/weak-password':
            errorMessage = 'Salasana on liian heikko.';
            break;
          // Lisää tarvittaessa muita virhekoodien tapauksia
          default:
            errorMessage = error.message; // Vaihtoehtoisesti voit näyttää Firebasen oman viestin
            break;
        }
      }

      showToast(errorMessage, { type: 'error' });
    }
  };

  const ResetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);

      showToast('Salasanan vaihtolinkki lähetetty sähköpostiisi. Tarkista sähköpostisi.', {
        type: 'success',
        autoClose: 5000,
        position: 'top-center',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          padding: '10px',
        },
      });
    } catch (error) {
      console.error('Error sending password reset email: ', error);
      let errorMessage = 'Tuntematon virhe tapahtui. Yritä uudelleen.';

      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/user-not-found':
            errorMessage = 'Käyttäjää ei löytynyt.';
            break;
          case 'auth/invalid-email':
            errorMessage = 'Sähköpostiosoite on virheellinen.';
            break;
          default:
            errorMessage = error.message;
            break;
        }
      }

      showToast(errorMessage, {
        type: 'error',
        autoClose: 5000,
        position: 'top-center',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          padding: '10px',
        },
      });
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setVerifiedEmail(false);
      setVerifiedUser(false);
      setUserBilling(false);
      setValidInvite(false);
      setUser(null);
      setBillingCustomer(null);
      setInviteEmail(null);

      showToast('Olet kirjautunut ulos', { type: 'success', position: 'bottom-center' });
    } catch (error) {
      let errorMessage = 'Uloskirjautuminen epäonnistui. Yritä uudelleen.';
      if (error instanceof FirebaseError) {
        // Voit lisätä tarkempaa virheiden käsittelyä tässä, jos tarpeen
        errorMessage = error.message;
      }

      showToast(errorMessage, { type: 'error' });
    }
  };

  if (pending) {
    return <></>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        verifiedUser,
        logout,
        registerWithEmailAndPassword,
        loginWithEmailAndPassword,
        verifiedEmail,
        userBilling,
        checkEmailVerification,
        setUserBilling,
        CheckBillingInfo,
        checkInvitationIsValid,
        validInvite,
        ResetPassword,
        setValidInvite,
        user,
        // loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
