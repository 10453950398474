import React from 'react';
import { Input } from '@material-tailwind/react';
import { Card } from '@material-tailwind/react';
import { Customer } from '../functions';
import { Typography } from '@mui/material';
const BasicInfo = ({ customer, updateCustomer, submitCustomer, errorMessages }: { customer: Customer; updateCustomer: any; submitCustomer: any; errorMessages: any }) => {
  const sx = { fontFamily: 'open-sans', fontSize: '1rem', fontWeight: 600, color: '#5c5c5c' };
  return (
    <Card placeholder={''} className="bg-greyBackground p-4 rounded-md shadow-md mb-4">
      <Typography className="text-almostBlack " sx={{ fontSize: '1.25rem', fontWeight: 600, color: '#5c5c5c', textAlign: 'center', fontFamily: 'Poppins' }}>
        Anna kohteen perustiedot
      </Typography>
      <div className="mb-4 flex flex-col gap-4 p-8 justify-center items-center w-full ">
        <div className="flex flex-col  gap-4  justify-center font-poppins w-full md:w-4/5 ">
          <Input
            required
            variant="standard"
            error={errorMessages.basicInfo ? true : false}
            crossOrigin="true"
            label="Kohteen nimi"
            color="light-blue"
            style={sx}
            value={customer.basicInfo.name}
            onChange={(e) => updateCustomer('basicInfo', 'name', e.target.value)}
          />
          <Input
            variant="standard"
            label="Postiosoite"
            style={sx}
            color="light-blue"
            crossOrigin="true"
            value={customer.basicInfo.street}
            onChange={(e) => updateCustomer('basicInfo', 'street', e.target.value)}
          />
          <Input
            variant="standard"
            label="Postinumero"
            crossOrigin="true"
            style={sx}
            color="light-blue"
            value={customer.basicInfo.postalCode}
            onChange={(e) => updateCustomer('basicInfo', 'postalCode', e.target.value)}
          />
          <Input
            variant="standard"
            label="Postitoimipaikka"
            style={sx}
            crossOrigin="true"
            color="light-blue"
            value={customer.basicInfo.city}
            onChange={(e) => updateCustomer('basicInfo', 'city', e.target.value)}
          />
        </div>
      </div>
    </Card>
  );
};

export default BasicInfo;
