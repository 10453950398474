import { Link } from 'react-router-dom';
type Content = {
  title: string;
  description: string;
};

export const features = [
  {
    title: 'Intuitiivinen käyttöliittymä',
    description:
      'Koe vaivaton hallinta uudella tasolla! Meidän kevyt käyttöliittymämme varmistaa, että pääset hyödyntämään kamerajärjestelmääsi heti, ilman syvällistä teknistä ymmärrystä. Hallinnoi turvallisuuttasi sujuvasti ja tehokkaasti.',
  },
  {
    title: '30 päivän huoleton tallennus ',
    description:
      'Unohda tallennustilan loppuminen! Tarjoamme 30 päivän tallennusajan suojatussa pilvipalvelussamme, mikä tarkoittaa, että voit olla huoletta tietäen, että arvokkaat kuvat ja videot ovat turvassa ja käytettävissä milloin tahansa.',
  },
  {
    title: 'Reaaliaikainen valvonta',
    description:
      'Pysy ajan tasalla ja hallitse kamerajärjestelmääsi reaaliajassa. Meidän hallintapaneelimme mahdollistaa välittömän pääsyn ja valvonnan, joten voit reagoida tapahtumiin välittömästi, missä ja milloin vain.',
  },
  {
    title: 'Helppo käyttöönotto',
    description: 'Aloita vaivatta! Meidän ratkaisumme tarjoaa nopean ja helpon käyttöönoton ilman monimutkaisia asennuksia. Nauti huippuluokan valvonnasta ilman teknisiä päänsärkyjä.',
  },
  {
    title: 'Luotettava kotimainen laatu',
    description:
      'Valitse luotettavuus ja laatu! Kotimaisena palveluntarjoajana takaamme korkean laadun, henkilökohtaisen tuen ja nopean avun silloin, kun sitä tarvitset. Meiltä saat palvelun, joka ymmärtää paikalliset tarpeet.',
  },
  {
    title: 'Turvallisuus ennen kaikkea',
    description:
      'Suojele arvokkaimpia hetkiäsi luotettavasti! Meidän pilvipohjainen tallennusratkaisumme on suunniteltu turvallisuutta silmällä pitäen, tarjoten sinulle mielenrauhaa tietäessäsi, että tietosi ovat suojattuja ja yksityisyytesi kunnioitettu.',
  },
] as Content[];

export const steps = [
  {
    title: 'Rekisteröidy',
    description: (
      <p>
        Luo{' '}
        <Link
          style={{
            color: '#0078D4',
            textDecoration: 'underline',
          }}
          to={'https://www.fsm.fi/index.php?type=request'}
        >
          FSM jälleenmyyjähakemus
        </Link>{' '}
        käyttääksesi OBSCloud-palvelua. Tilin avaamisen jälkeen saat sähköpostiisi vahvistuslinkin, jolla voit vahvistaa OBSCloud tilisi.
      </p>
    ),
  },
  {
    title: 'Luo tallennuskohde',
    description: 'Lisää kohteen yhteystiedot, määritä yhteyshenkilöt, kamerat ja käyttäjät.',
  },
  {
    title: 'Konfiguroi laitteet',
    description: 'Lataa reitittimen konfiguraatiotiedosto ja asenna kamerat paikalleen sekä liitä ne verkkoon ja aseta saamasi tunnukset kameraan.',
  },
  {
    title: 'Lataa etäkäyttösovellus',
    description: 'Lataa katselusovellus ja kirjaudu sisään sähköpostiisi saamillasi tunnuksilla.',
  },
] as Content[];
