import React from 'react';
import ReactDOM from 'react-dom/client';
import ToastProvider from './context/toastContext';
import App from './App.jsx';
import './index.css';
import { LoadingProvider } from './context/loadingContext';
import { AuthProvider } from './context/authContext';
import CustomerProvider from './context/customerContext';
import { ApiProvider } from './context/ApiCalls';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <LoadingProvider>
        <AuthProvider>
          <ApiProvider>
            <ToastProvider>
              <CustomerProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </CustomerProvider>
            </ToastProvider>
          </ApiProvider>
        </AuthProvider>
      </LoadingProvider>
    </BrowserRouter>
  </React.StrictMode>
);
